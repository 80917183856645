import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency } from "../../../../store/ticket-slice";
import { CircleDollarSign } from "lucide-react";
import { useTranslate } from "../../../../translations/hooks";

import BA from "../../../../assets/flags/BA.svg";
import EU from "../../../../assets/flags/EU.svg";
import USA from "../../../../assets/flags/USA.svg";

const CURRENCIES = [
  { code: "BAM", label: "BAM", flag: BA },
  { code: "EUR", label: "EUR", flag: EU },
  { code: "USD", label: "USD", flag: USA },
];

const CurrencySelect = () => {
  const dispatch = useDispatch();
  const t = useTranslate("buy");
  const currency = useSelector((state) => state.ticketStateExperimental.currency);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCurrencyChange = (code) => {
    dispatch(setCurrency(code));
    setIsDropdownOpen(false);
  };

  return (
    <div
      className={`currency-select-container ${
        isDropdownOpen ? "currency-select-container-open" : ""
      }`}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      <div className="currency-text">
        <CircleDollarSign />
        <span>{t("Currency")}</span>
      </div>
      <div className="custom-select">
        <div className="selected-option-currency">
          <img
            src={CURRENCIES.find((c) => c.code === currency)?.flag}
            alt={currency}
            className="currency-flag"
          />
          <span className="currency-styles">{currency}</span>
        </div>
        {isDropdownOpen && (
          <div className="dropdown-menu-currency">
            {CURRENCIES.map(({ code, label, flag }) =>
              code !== currency ? (
                <div
                  key={code}
                  className="dropdown-item-currency"
                  onClick={() => handleCurrencyChange(code)}
                >
                  <div className="currency-item">
                    <img src={flag} alt={label} className="currency-flag" />
                    <span className="currency-styles">{label}</span>
                  </div>
                </div>
              ) : null
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrencySelect;
