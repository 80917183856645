import { editor } from "@overlapmedia/imagemapper";
import React, { useMemo, useRef, useCallback, useEffect } from "react";

function OnlineImageMapper({
  options = {},
  style = {},
  cb,
  handleZoneClick,
  onlineSaleZones,
  zones,
}) {
  const elementRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) {
      const editorInstance = editor(elementRef.current, options, style);
      editorRef.current = editorInstance;
      if (cb) cb(editorInstance);
    }
    editorRef.current.selectMode();
  }, [options, style, cb]);

  const showTooltip = useCallback((e, data) => {
    const tooltip = document.getElementById("tooltip");

    let tooltipContent = `
        <p style="text-align: center; margin-bottom: -5px">Zona: ${data.zoneName || "N/A"}</p>
      `;

    if (data.ticket_name) {
      tooltipContent += `
          <div>
            <p>Online prodaja: ${data.ticket_name}</p>
            <p>Cijena: <small>${data.price || "0,00"} BAM</small></p>
            <small>
              Ukupan broj slobodnih sjedala: ${data.available_seats || 0} / ${
        data.total_amount || 0
      }
            </small>
          </div>
        `;
    }

    tooltip.innerHTML = tooltipContent;
    Object.assign(tooltip.style, {
      left: `${e.pageX}px`,
      top: `${e.pageY - 40}px`,
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    });
  }, []);

  const hideTooltip = useCallback(() => {
    document.getElementById("tooltip").style.display = "none";
  }, []);

  const showZoneTooltip = useCallback(
    (zoneData, zoneName, e) => {
      showTooltip(e, {
        zoneName,
        price: zones[zoneName]?.price || zoneData.price,
        total_amount: zones[zoneName]?.max_amount || zoneData.max_amount,
        available_seats: zones[zoneName]?.amount || zoneData.amount,
        ticket_name: zones[zoneName]?.name || zoneData.name,
      });
    },
    [showTooltip, zones]
  );

  const renderZones = useMemo(() => {
    if (!onlineSaleZones) return null;
    return Object.entries(onlineSaleZones).map(([zoneName, zoneData], index) => {
      const onlineSoldPrecentColor = Math.round((zoneData.amount / zoneData.max_amount) * 255) || 0;

      const containerStyle = zones[zoneName]?.on_sale
        ? `rgb(${250 - onlineSoldPrecentColor}, ${onlineSoldPrecentColor}, 10)`
        : "rgb(150,150,150)";

      const shapeProps = {
        fill: containerStyle,
        stroke: "rgb(100, 100, 100)",
        cursor: "pointer",
        strokeWidth: "8",
        opacity: "0.45",
        strokeDasharray: "none",
        strokeLinejoin: "round",
        id: zoneName,
        onTouchEndCapture: (e) => handleZoneClick(e.target, [zoneName, zoneData]),
        onClick: (e) => handleZoneClick(e.target, [zoneName, zoneData]),
        onMouseMove: (e) => showZoneTooltip(zoneData, zoneName, e),
        onMouseOut: hideTooltip,
      };

      return (
        <g key={`${zoneData?.location?.shape}_${index}`}>
          {zoneData?.location?.shape === "rect" && (
            <rect
              {...shapeProps}
              width={zoneData.location.size.width}
              height={zoneData.location.size.height}
              x={zoneData.location.position.x}
              y={zoneData.location.position.y}
            />
          )}
          {zoneData?.location?.shape === "polygon" && (
            <polygon
              {...shapeProps}
              points={zoneData.location.points}
              stroke="rgb(100, 100, 100)"
              strokeLinejoin="bevel"
            />
          )}
        </g>
      );
    });
  }, [onlineSaleZones, handleZoneClick, showZoneTooltip, hideTooltip, zones]);

  return (
    <svg
      className="image-map-svg"
      ref={elementRef}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={options.width}
      height={options.height}
      viewBox={`0, 0, ${options.width}, ${options.height}`}
      preserveAspectRatio="xMinYMin"
    >
      {renderZones}
    </svg>
  );
}

export default OnlineImageMapper;
