import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// Images
import X from "../assets/ikonice/X.svg";
import UserCard from "../assets/ikonice/user_card_icon.svg";
import PinIcon from "../assets/ikonice/pin_icon.svg";
import PasswordEye from "../assets/ikonice/invisible.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUserID } from "../store/userSlice";
// Components
import { Link } from "react-router-dom";
import { RegisterInput } from "./RegisterInput";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
// Functions

import * as EmailValidator from "email-validator";

import { toastSetup } from "../functions/toastSetup";
// Phone Input
import PhoneInput from "react-phone-number-input";
import countryMap from "../components/helper/countryMap";
import hr from "../components/helper/hr";
import { setLoginIsOpen } from "../store/loginSlice";
import { LanguageToggler } from "../components/LanguageToggler";
import en from "../components/helper/en";
export const Register = ({ isRegisterOpen, setIsRegisterOpen }) => {
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const repeatPasswordRef = useRef(null);
  const [email, setEmail] = useState("");
  const emailRef = useRef(null);
  // Phone Input settings
  const [phone, setPhone] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [verified, setVerified] = useState(false);
  const [country, setCountry] = useState("BA");
  const [isRegistrationInProgress, setIsRegistrationInProgress] = useState(false);

  const language = useSelector((state) => state.language);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    if (isRegistrationInProgress) {
      return;
    }
    setIsRegistrationInProgress(true);

    e.preventDefault();
    if (!agreed) {
      toast.error(
        language === "hr"
          ? "Niste potvrdili da se slažete s uvjetima korištenja."
          : "You haven't confirmed terms of use.",
        toastSetup("bottom-center", 3000)
      );
      return;
    }
    if (!verified) {
      toast.error(
        language === "hr"
          ? "Niste potvrdili da niste robot."
          : "You haven't confirmed you're not a robot.",
        toastSetup("bottom-center", 3000)
      );
      return;
    }
    const user = {
      full_name: e.target.elements.name.value + " " + e.target.elements.lname.value,
      email: email,
      fbEmail: email,
      address: e.target.elements.address.value,
      city: e.target.elements.city.value,
      country: countryMap[country],
      role: "standard",
      zip: e.target.elements.zipcode.value,
      phone: phone,
      password: e.target.elements.password.value,
      repeatPassword: e.target.elements.repeatPassword.value,
      buy_history: [],
      is_banned: false,
      newsletter: false,
      is_verified: false,
    };

    if (user.password === user.repeatPassword) {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "/api/v1/users",
          {
            user,
            language,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          toast.success(
            language === "hr"
              ? `Uspješna registracija. Provjerite ${user.email} zbog verifikacije!`
              : `Registration successful. Check your email for verification!`,
            toastSetup("top-right", 3000)
          );
          setIsRegisterOpen(false);
          // Login user
          dispatch(setUserID(response.data.id));
          dispatch(setToken(response.data.token));

          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.id);
        })
        .catch((error) => {
          // Handle any errors
          emailRef.current.focus();
          toast.error(
            language === "hr"
              ? `Došlo je do pogreške prilikom registracije. ${error.response.data.message}!`
              : `An error occurred during registration. User already exists with this email!`,
            toastSetup("top-right", 7000)
          );
        });
    } else {
      toast.warn(
        language === "hr" ? "Lozinke se ne podudaraju!" : "Passwords do not match!",
        toastSetup("top-right", 3000)
      );
      repeatPasswordRef.current.focus();
    }
    setIsRegistrationInProgress(false);
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains("login-screen")) {
      setIsRegisterOpen(false);
    }
  };

  const handleOpenLogin = (e) => {
    e.preventDefault();
    setIsRegisterOpen(false);

    dispatch(setLoginIsOpen(true));
  };

  const handleUserTerms = (e) => {
    e.preventDefault();
    setIsRegisterOpen(false);
    navigate("/informations?page_type=terms_of_use_buyer");
  };

  const checkValidity = () => {
    !EmailValidator.validate(email) &&
      toast.warning(
        language === "hr" ? "Provjerite ispravnost e-maila!" : "Check the email correctness!"
      );
  };

  return (
    <div className="login-box">
      <div className="login-screen" onClick={handleModalClick}>
        <div className="container">
          <button
            className="close-btn"
            onClick={() => {
              setIsRegisterOpen(!isRegisterOpen);
            }}
          >
            <img src={X} alt="" />
          </button>
          <div style={{ position: "absolute", top: "2%", left: "3%" }}>
            <LanguageToggler />
          </div>
          <h2 style={{ margin: "25% auto -5px auto" }}>
            {language === "hr" ? "Registracija" : "Registration"}
          </h2>
          <div className="text-section">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="multiple-inputs-wrapper">
                <RegisterInput
                  placeholder={language === "hr" ? "Ime" : "Name"}
                  type="text"
                  icon={UserCard}
                  name="name"
                  isRequired={true}
                  inputLength={3}
                />
                <RegisterInput
                  placeholder={language === "hr" ? "Prezime" : "Last name"}
                  type="text"
                  icon={UserCard}
                  name="lname"
                  isRequired={true}
                  inputLength={3}
                />
              </div>
              {/* Special email check for domains used */}
              <input
                ref={emailRef}
                style={{ marginBottom: "20px" }}
                placeholder={language === "hr" ? "Email" : "Email"}
                type="email"
                className="sign-up-inputs"
                required={true}
                value={email}
                onFocus={() =>
                  toast.warn(
                    language === "hr"
                      ? "Preporuka: Koristite neke od javnih e-mail domena, poput gmail.com ili yahoo.com..."
                      : "Recommendation: Use one of the public e-mail domains, like gmail.com or yahoo.com..."
                  )
                }
                onBlur={checkValidity}
                onChange={(e) => setEmail(e.target.value)}
              ></input>

              <RegisterInput
                placeholder={language === "hr" ? "Adresa" : "Address"}
                type="text"
                icon={PinIcon}
                name="address"
                isRequired={false}
              />
              <div className="multiple-inputs-wrapper">
                <RegisterInput
                  placeholder={language === "hr" ? "Grad" : "City"}
                  type="text"
                  icon=""
                  name="city"
                  isRequired={false}
                />
                <RegisterInput
                  placeholder={language === "hr" ? "Poštanski broj" : "Zipcode"}
                  type="number"
                  icon=""
                  name="zipcode"
                  isRequired={false}
                />
              </div>
              <PhoneInput
                placeholder={language === "hr" ? "Mobitel" : "Phone"}
                value={phone || ""}
                onChange={setPhone}
                onCountryChange={setCountry}
                defaultCountry={country || "BA"}
                international={true}
                countryCallingCodeEditable={false}
                countryOptionsOrder={[
                  "BA",
                  "HR",
                  "NL",
                  "DE",
                  "HU",
                  "RS",
                  "AL",
                  "BG",
                  "GR",
                  "XK",
                  "ME",
                  "MK",
                  "RO",
                  "SI",
                  "AT",
                  "IT",
                ]}
                labels={language === "hr" ? hr : en}
                locales="hr"
              />
              <RegisterInput
                placeholder={language === "hr" ? "Lozinka" : "Password"}
                type="password"
                icon={PasswordEye}
                cursorPointer={true}
                name="password"
                isRequired={true}
                inputLength={6}
                isPasswordVisible={isPasswordVisible}
                setIsPasswordVisible={setIsPasswordVisible}
              />
              <RegisterInput
                placeholder={language === "hr" ? "Ponovi Lozinku" : "Repeat Password"}
                type="password"
                icon=""
                ref={repeatPasswordRef}
                name="repeatPassword"
                isRequired={true}
                inputLength={6}
                isPasswordVisible={isPasswordVisible}
                setIsPasswordVisible={setIsPasswordVisible}
              />
              <p>
                {language === "hr" ? "Imaš event.ba račun?" : "Do you have an event.ba account?"}{" "}
                <Link onClick={handleOpenLogin}>
                  {language === "hr" ? "Prijavi se." : "Log in."}
                </Link>
              </p>
              <div
                className="terms-of-use-container"
                style={{ justifyContent: "center", gap: "10px" }}
              >
                <p>
                  {language === "hr" ? "Pročitao sam i slažem se s" : "I have read and agree with"}{" "}
                  <Link onClick={handleUserTerms}>
                    {language === "hr" ? "Uvjetima korištenja" : "Terms Of Use"}
                  </Link>
                </p>
                <input
                  className="terms-of-use"
                  type="checkbox"
                  onChange={() => setAgreed((agreed) => !agreed)}
                />
              </div>
              <ReCAPTCHA
                style={{
                  borderRadius: "15px",
                  overflow: "hidden",
                  height: "75px",
                  width: "295px",
                  marginBottom: "15px",
                }}
                className="recaptcha"
                sitekey="6LeMm1MnAAAAAOElXfMI6txzQnUG3q2F4QVUnYYq"
                onChange={() => setVerified(true)}
                theme="dark"
                hl={language}
              />

              <button type="submit" className="login-btn" disabled={isRegistrationInProgress}>
                {language === "hr" ? "Registriraj se!" : "Register!"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
