// Color categories
const BUDGET_COLORS_HSL = [
  { h: 220, s: 15, l: 49 }, // #94a3b8
  { h: 87, s: 73, l: 35 }, // #65a30d
  { h: 50, s: 99, l: 34 }, // #fcd34d
  { h: 168, s: 58, l: 24 }, // #34d399
];

const REGULAR_COLORS_HSL = [
  { h: 24, s: 96, l: 44 }, // #fb923c
  { h: 18, s: 94, l: 35 }, // #f97316
  { h: 36, s: 81, l: 22 }, // #d97706
  { h: 90, s: 85, l: 33 }, // #a3e635
];

const PREMIUM_COLORS_HSL = [
  { h: 136, s: 76, l: 22 }, // #16a34a
  { h: 155, s: 91, l: 26 }, // #059669
  { h: 171, s: 80, l: 15 }, // #0f766e
  { h: 173, s: 81, l: 32 }, // #14b8a6
];

const EXCLUSIVE_COLORS_HSL = [
  { h: 193, s: 91, l: 36 }, // #0891b2
  { h: 204, s: 85, l: 54 }, // #0ea5e9
  { h: 243, s: 60, l: 55 }, // #6366f1
  { h: 280, s: 85, l: 61 }, // #a855f7
  { h: 300, s: 85, l: 74 }, // #e879f9
  { h: 330, s: 89, l: 64 }, // #f472b6
];

const VIP_COLORS_HSL = [
  { h: 265, s: 74, l: 31 }, // #6d28d9
  { h: 274, s: 73, l: 36 }, // #7e22ce
  { h: 312, s: 71, l: 37 }, // #a21caf
  { h: 328, s: 77, l: 42 }, // #be185d
  { h: 348, s: 78, l: 39 }, // #be123c
  { h: 356, s: 82, l: 50 }, // #dc2626
];

const COLOR_CATEGORIES = [
  { name: "Budget", colors: BUDGET_COLORS_HSL, max: 40 },
  { name: "Regular", colors: REGULAR_COLORS_HSL, min: 41, max: 60 },
  { name: "Premium", colors: PREMIUM_COLORS_HSL, min: 61, max: 80 },
  { name: "Exclusive", colors: EXCLUSIVE_COLORS_HSL, min: 81, max: 98 },
  { name: "VIP", colors: VIP_COLORS_HSL, min: 99 },
];

// Helper: Apply hue rotation
const applyHueRotationHsl = (color, degree) => {
  return {
    h: (color.h + degree) % 360,
    s: color.s,
    l: color.l,
  };
};

export const assignColorsToZones = (zones) => {
  const zonesArray = Object.entries(zones);

  // Filter zones with price > 0 and on_sale === true
  const zonesWithPrice = zonesArray.filter(([, zone]) => zone.price > 0 && zone?.on_sale !== false);

  // Sort zones by price in ascending order
  zonesWithPrice.sort((a, b) => a[1].price - b[1].price);

  // Get the min and max prices for normalization
  const minPrice = zonesWithPrice[0]?.[1].price || 0;
  const maxPrice = zonesWithPrice[zonesWithPrice.length - 1]?.[1].price || 0;

  // Preprocess category data with price ranges and assigned colors
  const categoryColors = COLOR_CATEGORIES.map(
    ({ name, colors, min = -Infinity, max = Infinity }) => {
      return { name, colors, min, max };
    }
  );

  // Normalize prices and assign base colors
  const priceToBaseColor = new Map();
  const prefixShadeMap = new Map(); // Tracks hue rotation per prefix and price
  let colorIndex = 0;

  // Assign base colors to prices
  zonesWithPrice.forEach(([key, zone]) => {
    if (!priceToBaseColor.has(zone.price)) {
      const normalizedPrice = ((zone.price - minPrice) / (maxPrice - minPrice)) * 100;
      const category = categoryColors.find(
        ({ min, max }) => normalizedPrice >= min && normalizedPrice <= max
      );

      // Ensure the same price and prefix combination gets the same base color
      const colors = category?.colors || [{ h: 0, s: 0, l: 50 }];
      const prefix = key.split(" ")[0]; // Extract the prefix part (e.g., "Zone1")
      const prefixKey = `${prefix}-${zone.price}`;

      // Only assign the base color the first time for a given prefix-price combination
      if (!priceToBaseColor.has(prefixKey)) {
        priceToBaseColor.set(prefixKey, colors[colorIndex % colors.length]);
        colorIndex++;
      }
    }
  });

  // Assign colors with hue rotation for prefixes and prices
  const updatedZones = {};

  zonesArray.forEach(([key, zone]) => {
    if (zone.amount > 0 && zone?.on_sale !== false) {
      const prefix = key.split(" ")[0]; // Get the prefix part (e.g., "Zone1")
      const baseColor = priceToBaseColor.get(`${prefix}-${zone.price}`); // Get base color for prefix-price

      // Track hue rotation for the same prefix-price combination
      const prefixKey = `${prefix}-${zone.price}`; // Group by prefix and price
      if (!prefixShadeMap.has(prefixKey)) {
        prefixShadeMap.set(prefixKey, 0); // Start with no rotation
      }

      const hueRotation = prefixShadeMap.get(prefixKey); // Get the current hue rotation
      const shadeColor = applyHueRotationHsl(baseColor, hueRotation); // Apply the hue rotation

      // Increment hue rotation for the next zone in the same group
      prefixShadeMap.set(prefixKey, hueRotation + 20); // Increment hue rotation for the next zone

      updatedZones[key] = {
        ...zone,
        color: `hsl(${shadeColor.h}, ${shadeColor.s}%, ${shadeColor.l}%)`,
      };
    } else {
      updatedZones[key] = { location: zone?.location, color: "hsl(0, 0%, 50%)" };
    }
  });

  // Sort the updatedZones object by price (from zones array)
  const sortedUpdatedZones = Object.entries(updatedZones)
    .sort((a, b) => a[1].price - b[1].price) // Sort by price
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  return sortedUpdatedZones;
};

export const addTransparencyToHsl = (hslString, alpha) => {
  const [_, h, s, l] = hslString.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
  return `hsla(${h}, ${s}%, ${l}%, ${alpha})`;
};
