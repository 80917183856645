import React, { forwardRef, useEffect, useRef, useState } from "react";
import { InputFieldPayment } from "../../organize-event-page/Inputs";

import { Check } from "lucide-react";
import { useTranslate } from "../../../../translations/hooks";

export const SelectCustom = forwardRef(
  ({ name, label, errorMessage, isError, options, onChange, align, value, ...rest }, ref) => {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);
    const dropdownRef = useRef();
    const searchRef = useRef();
    const t = useTranslate("common");

    useEffect(() => {
      if (showMenu && searchRef.current) {
        searchRef.current.focus();
      }
    }, [showMenu]);

    useEffect(() => {
      const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
          handleBlur(); // Handle blur when clicking outside
        }
      };
      window.addEventListener("click", handleClickOutside);
      return () => window.removeEventListener("click", handleClickOutside);
    }, [value]);

    const handleBlur = () => {
      setSelectedValue(value);
      setShowMenu(false);
    };

    const getDisplay = () => (
      <InputFieldPayment
        key={name}
        label={label}
        type="text"
        placeholder={label}
        shadow={false}
        isError={isError}
        errorMessage={errorMessage}
        grow="true"
        className="payment-form-field"
        value={selectedValue}
        ref={searchRef}
        onChange={(e) => setSelectedValue(e.target.value)}
        onFocus={() => setShowMenu(true)} // Reopen the menu on focus
      />
    );

    const onItemClick = (value) => {
      setSelectedValue(value);
      onChange(value);
      setShowMenu(false); // Close menu after selection
    };

    const filteredOptions = selectedValue
      ? options.filter((option) => option.label.toLowerCase().includes(selectedValue.toLowerCase()))
      : options;

    return (
      <div className="custom--dropdown-container" ref={dropdownRef}>
        {getDisplay()}
        {showMenu && (
          <div className={`dropdown-menu-custom alignment--${align || "auto"}`}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => {
                const isSelected = option.value === value;

                return (
                  <div
                    key={option.value}
                    className={`dropdown-item ${isSelected ? "selected" : ""}`}
                    onClick={() => onItemClick(option.value)}
                  >
                    {isSelected && <Check size={18} />} {option.label}
                  </div>
                );
              })
            ) : (
              <div className="dropdown-item center">{t("no_results")}</div>
            )}
          </div>
        )}
      </div>
    );
  }
);
