import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ShoppingCart, Info } from "lucide-react";

export const ImageCard = ({ data }) => {
  const { poster, _id } = data || {};
  const src = poster?.portrait;
  const id = _id;

  const linkRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const linkElement = linkRef.current;
    const overlayElement = overlayRef.current;

    if (linkElement && overlayElement) {
      const handleMouseOver = () => {
        overlayElement.style.opacity = "1";
        linkElement.style.opacity = "1";
      };

      const handleMouseLeave = () => {
        overlayElement.style.opacity = "0";
        linkElement.style.opacity = "0";
      };

      linkElement.addEventListener("mouseover", handleMouseOver);
      linkElement.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        linkElement.removeEventListener("mouseover", handleMouseOver);
        linkElement.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, []);

  return (
    <div className="slider-image-container">
      <img
        className="slider-img"
        src={src ? `${process.env.REACT_APP_API_URL}/static/event-images/${src}` : ""}
        alt="Event"
        loading="lazy"
      />
      <div className="slider-img-overlay" ref={overlayRef}></div>
      <div className="links-container-hero" ref={linkRef}>
        {id && (
          <div className="links-flex">
            <Link to={`/single/${id}`}>
              <Info size={20} />
              <span>Pogledaj</span>
            </Link>
            <Link to={`/buy/${id}`}>
              <ShoppingCart size={20} />
              <span>Kupi</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
