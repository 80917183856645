import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslate } from "../../../../translations/hooks";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  setActiveTicket,
  setClientSecretAndAuthenticityToken,
} from "../../../../store/ticket-slice";
import { InputFieldPayment } from "../../organize-event-page/Inputs";
import countryMap from "../../../../components/helper/countryMap";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  UserPen,
  TriangleAlert,
  ClockAlert,
  ShieldAlert,
  CircleCheckBig,
  ShoppingCart,
  ListTodo,
} from "lucide-react";
import customToast from "./CustomToast";
import Button from "../../../../components/Button";
import Timer from "./Timer";
import { SelectCustom } from "./CustomSelectDropdown";

const reversedCountryMap = Object.fromEntries(
  Object.entries(countryMap).map(([key, value]) => [value, key])
);

const countryOptions = Object.entries(countryMap).map(([key, name]) => ({
  label: `${name} - ${key}`,
  value: key,
}));

const PaymentForm = ({ profileData, clientSecret, authenticityToken }) => {
  const t = useTranslate("payment-form");

  const monriRef = useRef({
    monri: null,
    components: null,
    cardElement: null,
  });
  const cardElementId = "card-element";

  const selectRef = useRef(null);
  const successRef = useRef(null);
  const errorRef = useRef(null);
  const [fieldErrors, setFieldErrors] = useState({
    card: "",
    cvv: "",
    expiryDate: "",
  });
  const [error, setError] = useState("");
  const [monriErrors, setMonriErrors] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successURL, setSuccessURL] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const locale = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const transactionSchema = z.object({
    fullName: z.string().min(3, t("errors.full_name.min")).max(30, t("errors.full_name.max")),
    address: z.string().min(3, t("errors.address.min")).max(100, t("errors.address.max")),
    city: z.string().min(3, t("errors.city.min")).max(30, t("errors.city.max")),
    zip: z.string().min(3, t("errors.zip.min")).max(9, t("errors.zip.max")),
    country: z.enum(Object.keys(countryMap), t("errors.country.invalid")),
    phone: z.string().min(3, t("errors.phone.min")).max(30, t("errors.phone.max")),
    email: z
      .string()
      .email(t("errors.email.format"))
      .min(3, t("errors.email.min"))
      .max(100, t("errors.email.max")),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(transactionSchema),
    defaultValues: {
      fullName: profileData.full_name || "",
      address: profileData.address || "",
      city: profileData.city || "",
      zip: String(profileData.zip) || "",
      country: reversedCountryMap[profileData.country] || "",
      phone: profileData.phone || "",
      email: profileData.email || "",
    },
  });

  const countryCode = watch("country");

  useEffect(() => {
    if (window.Monri) {
      const monri = new window.Monri(authenticityToken, {
        locale,
        fonts: [
          { family: "Open Sans", src: "https://event.ba/static/fonts/OpenSans-Regular.ttf" },
          {
            family: "Open Sans",
            src: "https://event.ba/static/fonts/OpenSans-Medium.ttf",
            weight: "500",
          },
          {
            family: "Open Sans",
            src: "https://event.ba/static/fonts/OpenSans-SemiBold.ttf",
            weight: "600",
          },
          {
            family: "Open Sans",
            src: "https://event.ba/static/fonts/OpenSans-Bold.ttf",
            weight: "700",
          },
        ],
      });

      const components = monri.components({ clientSecret });
      const cardElement = components.create("card", { style });
      cardElement.mount(cardElementId);

      monriRef.current = { monri, components, cardElement };

      // Add listeners for error handling
      const handleError = (field) => (event) => {
        setError((prev) => prev && "");
        setFieldErrors((prev) => ({
          ...prev,
          [field]: event.message || "",
        }));
      };

      cardElement.addChangeListener("card_number", handleError("card"));
      cardElement.addChangeListener("cvv", handleError("cvv"));
      cardElement.addChangeListener("expiry_date", handleError("expiryDate"));

      return () => {
        dispatch(setClientSecretAndAuthenticityToken({}));
      };
    }
  }, [authenticityToken, clientSecret, locale, dispatch]);

  const handlePayment = async (data) => {
    if (!monriRef.current) {
      customToast({
        type: "error",
        main: t("errors.monri_not_initialized"),
        details: t("errors.monri_not_initialized_details"),
      });
      return;
    }

    const { monri, cardElement } = monriRef.current;
    setIsLoading(true);

    try {
      const monriResponse = await monri.confirmPayment(cardElement, data);
      if (monriResponse.result && monriResponse.result.status === "approved") {
        customToast({
          type: "success",
          main: t("success.payment_successful"),
          details: t("success.payment_successful_details_toast"),
        });
        setError("");
        setIsSuccess(true);
        setSuccessURL(monriResponse?.result?.success_url);
        // Scroll to the success message
        successRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      } else {
        // Collect all errors into a single array
        const errorsToReport = [];
        if (monriResponse?.result?.errors) {
          errorsToReport.push(...monriResponse.result.errors.message); // Include array of detailed errors
        }
        if (monriResponse?.error?.message) {
          errorsToReport.push(monriResponse.error.message); // Include the string error as an object
        }
        // Send the collected errors to the backend
        if (errorsToReport.length > 0) {
          sendErrorsToBackend({ clientSecret, monriErrors: errorsToReport });
        }

        // Show appropriate toast messages
        if (monriResponse.result && monriResponse.result.status === "declined") {
          setMonriErrors(monriResponse?.result?.errors || []);
          customToast({
            type: "info",
            main: t("declined.payment_declined"),
            details: monriResponse.error?.message || t("declined.payment_declined_details"),
          });
        } else if (monriResponse?.error?.message) {
          setError(monriResponse?.error?.message);
          customToast({
            type: "error",
            main: t("errors.payment_error"),
            details: monriResponse?.error?.message || t("errors.payment_error_details"),
          });
        } else {
          customToast({
            type: "error",
            main: t("errors.payment_error"),
            details: t("errors.payment_error_details"),
          });
        }
        errorRef.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    } catch (err) {
      customToast({
        type: "error",
        main: t("errors.payment_error"),
        details: t("errors.payment_error_details"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sendErrorsToBackend = async ({ monriErrors, clientSecret }) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/monri_payment/send_errors`, {
        monriErrors,
        clientSecret,
      });
    } catch (error) {
      console.error("Failed to send errors to backend:", error);
    }
  };

  const getBackOnBuy = () => {
    dispatch(setClientSecretAndAuthenticityToken({}));
    dispatch(setActiveTicket(1));
    setIsSuccess(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="payment-header">
        <span className="text-with-icon">
          <UserPen size={20} color="#00112b" />
          <span className="main-text">{t("header")}</span>
        </span>
        <span className="secondary-text">{t("sub_header")}</span>
      </div>
      <form onSubmit={handleSubmit(handlePayment)} className="payment-form">
        {[
          { label: t("fields.full_name"), name: "fullName" },
          { label: t("fields.address"), name: "address" },
          { label: t("fields.city"), name: "city" },
          { label: t("fields.zip"), name: "zip" },
        ].map(({ label, name }) => (
          <InputFieldPayment
            key={name}
            label={label}
            type="text"
            placeholder={label}
            className="payment-form-field"
            shadow={false}
            isError={!!errors[name]}
            errorMessage={errors[name]?.message || ""}
            grow="true"
            {...register(name)}
          />
        ))}
        <SelectCustom
          options={countryOptions}
          name="country"
          label={t("fields.country")}
          onChange={(value) => setValue("country", value, { shouldValidate: true })} // Update form value
          isError={!!errors["country"]}
          errorMessage={errors["country"]?.message || ""}
          ref={selectRef}
          error={errors.country?.message}
          value={countryCode}
        />
        {[
          { label: t("fields.phone"), name: "phone" },
          { label: t("fields.email"), name: "email" },
        ].map(({ label, name }) => (
          <InputFieldPayment
            key={name}
            label={label}
            type="text"
            placeholder={label}
            className="payment-form-field"
            shadow={false}
            isError={!!errors[name]}
            errorMessage={errors[name]?.message || ""}
            grow="true"
            {...register(name)}
          />
        ))}

        <div id={cardElementId} className="card-element"></div>
        {fieldErrors.card && (
          <div id="card-error">
            <TriangleAlert size={18} />
            {fieldErrors.card}
          </div>
        )}
        {fieldErrors.expiryDate && (
          <div id="card-error">
            <ClockAlert size={18} />
            {fieldErrors.expiryDate}
          </div>
        )}
        {fieldErrors.cvv && (
          <div id="card-error">
            <ShieldAlert size={18} />
            {fieldErrors.cvv}
          </div>
        )}
        <div className="payment-message-wrapper" ref={errorRef}>
          {error && (
            <>
              <div className="payment-error-header main-text">
                <TriangleAlert size={22} />
                {t("errors.payment_error")}
              </div>
              <div className="payment-error-message">{error}</div>
              {monriErrors.length > 0 && (
                <div className="payment-errors">
                  {monriErrors.map((error, i) => (
                    <div key={i} id="card-error">
                      {error}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <div className="payment-message-wrapper" ref={successRef}>
          {isSuccess && (
            <>
              <div className="payment-error-header main-text">
                <CircleCheckBig size={22} color="rgb(7 112 59)" />
                {t("success.payment_successful")}
              </div>
              <div className="sub-main-text" style={{ color: "rgb(7 112 59)" }}>
                {t("success.payment_successful_header")}
              </div>
              <div className="secondary-text success-text">
                {t("success.payment_successful_details", { email: getValues("email") })}
                <Link
                  style={{ color: "rgb(7 112 59)", textDecoration: "none", fontWeight: "600" }}
                  to={"/profile?activeNavItem=Moje ulaznice"}
                >
                  {" "}
                  {t("success.link_to_profile")}.
                </Link>
              </div>
              <span className="secondary-text success-text">
                {t("success.payment_successful_footer")}
              </span>
            </>
          )}
        </div>
        <div className="payment-button-wrapper">
          <Button
            type="button"
            onClick={getBackOnBuy}
            disabled={isLoading}
            className="secondary-buy-button"
            icon={ShoppingCart}
          >
            {isLoading ? t("buttons.processing") : t("buttons.back_to_buy")}
          </Button>
          {!isSuccess && <Timer isLoading={isLoading} isSuccess={isSuccess} />}
          {isSuccess && successURL && (
            <Button
              type="button"
              disabled={isLoading}
              className="secondary-buy-button"
              icon={ListTodo}
              onClick={() => window.open(successURL, "_blank")}
            >
              {isLoading ? t("buttons.processing") : t("buttons.payment_status")}
            </Button>
          )}

          {!isSuccess && (
            <Button
              type="submit"
              disabled={isLoading}
              className="primary-buy-button"
              icon={CircleCheckBig}
            >
              {isLoading ? t("buttons.processing") : t("buttons.confirm_payment")}
            </Button>
          )}
        </div>
      </form>
    </>
  );
};

export default PaymentForm;

const style = {
  base: {
    fontFamily:
      "Open Sans,Segoe UI, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    fontWeight: 500,
  },
  invalid: {},
  label: {
    base: {
      textTransform: "none",
      letterSpacing: "0px",
      color: "#888",
      fontSize: "0.8rem",
      margin: "0 0 0 5px",
    },
    invalid: {},
    complete: {},
  },
  input: {
    base: {
      fontSize: "0.9rem",
      color: "#777",
      fontWeight: "600",
      letterSpacing: "0px",
      backgroundColor: "transparent",
    },
    complete: {
      outline: "none",
    },
    invalid: {
      color: "rgb(166, 12, 12)",
      borderBottom: "1px solid rgb(166, 12, 12)",
    },
  },
};
