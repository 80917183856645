import { lightenHsl } from "./ImageZoneMapper";

const Badge = ({ color, className = "", children }) => {
  const styleBadge = {
    color: color,
    backgroundColor: lightenHsl(color, 95),
    outline: `solid 0.1rem ${lightenHsl(color, 50)}`,
  };
  return (
    <div className={`${className} ticket-badge`} style={styleBadge}>
      {children}
    </div>
  );
};

export default Badge;
