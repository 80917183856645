import { useEffect, useState } from "react";
import { SeatsModal } from "./SeatsModal";
import ImageZoneMapper from "./ImageZoneMapper";
import { selectTicket } from "../../../../store/ticket-slice";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useTranslate } from "../../../../translations/hooks";

export const MappedTickets = ({ concertData }) => {
  const [groundPlanImg, setGroundPlanImg] = useState(null); // Image dimensions and source
  const [selectedZone, setSelectedZone] = useState(false); // Selected zone
  const [modal, setModal] = useState(false); // Modal toggle
  const ticketId = useSelector((state) => state.ticketStateExperimental.activeTicket);

  const onlineSaleZones = useSelector((state) => state.ticketStateExperimental.zones);
  const t = useTranslate("buy");
  const category = useSelector(
    (state) =>
      state.ticketStateExperimental.tickets.find((ticket) => ticket.id === ticketId)?.category
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!concertData?.place?.ground_plan) return;

    const imageUrl = `${process.env.REACT_APP_API_URL}/static/ground-plans/${concertData.place.ground_plan}`;
    const imgElement = new Image();

    imgElement.onload = () => {
      setGroundPlanImg({
        src: imageUrl,
        width: imgElement.naturalWidth,
        height: imgElement.naturalHeight,
      });
    };

    imgElement.onerror = () => {
      toast.error("Failed to load ground plan image");
    };

    imgElement.src = imageUrl; // Start loading the image
  }, [concertData]);

  const handleZoneClick = (zone) => {
    setSelectedZone(zone);
    setModal(true);
    if (!onlineSaleZones[zone]?.rows) {
      dispatch(
        selectTicket({
          ticketId,
          category: zone,
          price: onlineSaleZones[zone].price,
          ticketName: onlineSaleZones[zone].name,
          color: onlineSaleZones[zone].color,
          ticketAvailableErrorMsg: t("ticket_available_error_msg"),
        })
      );

      return;
    }
  };

  useEffect(() => {
    setSelectedZone(category);
  }, [ticketId]);

  return (
    <div className="mapper-wrapper">
      {modal && (
        <SeatsModal
          selectedZone={selectedZone}
          onlineSaleZones={onlineSaleZones}
          ticketId={ticketId}
        />
      )}

      {groundPlanImg && (
        <div
          className="image-map-container"
          style={{
            backgroundImage: `url(${groundPlanImg.src})`,
          }}
        >
          <ImageZoneMapper
            options={{
              width: groundPlanImg.width,
              height: groundPlanImg.height,
            }}
            zones={onlineSaleZones}
            selectedZone={selectedZone}
            handleZoneClick={handleZoneClick}
          />
        </div>
      )}
    </div>
  );
};
