import React from "react";
import { useState, useEffect } from "react";

import centralna from "../assets/logos/centralna.png";
import event from "../assets/logos/eventba.png";
import NSBiH from "../assets/logos/NSBiH-Logo.png";
import KSBiH from "../assets/logos/KSBiH-Logo.png";

export const SponsorBar = () => {
  const logos = [
    { src: NSBiH, alt: "NSBiH" },
    { src: centralna, alt: "Centralna" },
    { src: event, alt: "Event BA" },
    { src: KSBiH, alt: "KSBiH" },
  ];

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = width <= 600;
  const isTablet = width > 600 && width <= 1000;
  const isDesktop = width > 1000 && width <= 1400;
  const isWideDesktop = width > 1400;

  const duplicateLogos = isMobile ? 1 : isTablet ? 2 : isDesktop ? 3 : isWideDesktop ? 4 : 2;

  return (
    <div className="sponsor-bar">
      <div className="sponsor-scroll">
        <div className="logos">
          <div className="logos-slide">
            <div className="main-part">
              {Array(duplicateLogos)
                .fill(logos)
                .flat()
                .map((logo, index) => (
                  <img key={`img-${index}`} src={logo.src} alt={logo.alt} />
                ))}
            </div>
            <div className="second-part">
              {Array(duplicateLogos)
                .fill(logos)
                .flat()
                .map((logo, index) => (
                  <img key={`img-${index}`} src={logo.src} alt={logo.alt} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
