import React, { useState } from "react";
import { EventCard } from "./EventCard";
import TicketTypeSwitcher from "../add-tickets/TicketTypeSwitcher";

export const MyEvent = ({ organizerEvents }) => {
  const [currentView, setCurrentView] = useState(true);

  // Split events into upcoming and past events
  const [upcomingEvents, pastEvents] = organizerEvents.reduce(
    (acc, event) =>
      new Date(event.time_of_event) > new Date()
        ? [[...acc[0], event], acc[1]]
        : [acc[0], [...acc[1], event]],
    [[], []]
  );

  // Sort events by time in descending order
  const sortedUpcomingEvents = upcomingEvents.sort(
    (a, b) => new Date(a.time_of_event) - new Date(b.time_of_event)
  );

  const sortedPastEvents = pastEvents.sort(
    (a, b) => new Date(b.time_of_event) - new Date(a.time_of_event)
  );

  return (
    <div className="mytickets-container my-events">
      <div className="button-wrapper-my-events">
        <TicketTypeSwitcher
          ticketState={currentView}
          setTicketState={setCurrentView}
          statesArray={["Završeni događaji", "Nadolazeći događaji"]}
        />
      </div>
      {currentView
        ? sortedUpcomingEvents.length > 0 &&
          sortedUpcomingEvents.map((e, i) => <EventCard key={i} ids={e} i={i} />)
        : sortedPastEvents.length > 0 &&
          sortedPastEvents.map((e, i) => <EventCard key={i} ids={e} i={i} past={true} />)}
    </div>
  );
};
