import React from "react";
import { toast } from "react-toastify";

const customToast = ({ type, main, details, time = 5000 }) => {
  const newLines = details
    ? details.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))
    : null;

  const renderToast = () => {
    switch (type) {
      case "success":
        return toast.success;
      case "info":
        return toast.info;
      case "warning":
        return toast.warn;
      case "error":
        return toast.error;
      default:
        return toast;
    }
  };

  renderToast()(
    <div className="toast-flex">
      <div className="main-text border-bottom-fade">{main}</div>
      {newLines && <span className="sub-main-text">{newLines}</span>}
    </div>,
    {
      autoClose: time, // Customize the auto-close time if needed
    }
  );
};

export default customToast;
