import React, { memo } from "react";
import { hrTimeFormat } from "../../../../components/helper/timeFormat";
import { Clock, MapPinHouse } from "lucide-react";

const EventInfo = ({ concertData }) => {
  const { performer_name, time_of_event, place } = concertData || {};
  const city = place?.city;
  const placeName = place?.place;

  return (
    <div className="event-info-container">
      <img
        src={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData?.poster?.landscape}`}
        alt="Landscape poster of event"
      />
      <div className="image-overlay">
        <div className="event-name-wrapper">
          <span className="event-name">{performer_name}</span>
        </div>
        <div className="text-with-icon">
          <Clock />
          <span className="card-main-info">
            {new Date(time_of_event).toLocaleString("hr-HR", hrTimeFormat)}
          </span>
        </div>
        <div className="text-with-icon">
          <MapPinHouse />
          <span className="card-main-info">
            {city}, {placeName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(EventInfo, (prevProps, nextProps) => {
  return prevProps.concertData === nextProps.concertData;
});
