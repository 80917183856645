import HallTickets from "./HallTickets";
import { Tag, Trash2 } from "lucide-react";
import { useTranslate } from "../../../../translations/hooks";
import TicketInputForm from "./TicketInputForm";
import { useState } from "react";
import { CustomSwitcher } from "../../organize-event-page/Inputs";

const Personalization = ({
  ticket,
  credentialsRequired,
  placeType,
  userEmail,
  userFullName,
  handleRemoveTicket,
}) => {
  const t = useTranslate("common");
  const [openPersonalization, setOpenPersonalization] = useState(credentialsRequired);

  return (
    <div className="personalization">
      <div className="ticket-heading">
        <div className="ticket-header-left">
          <span className="ticket-title text-with-icon text-nobrake">
            <Tag size={22} />
            {t("ticket")} {ticket.id}
          </span>
          {credentialsRequired ? (
            <span className="info-text">
              Organizator zahtjeva osobne podatke na ulaznici. Kupovinom pristajete na prikaz istih
              na ulaznici.
            </span>
          ) : (
            <CustomSwitcher
              id={`open-personalization-${ticket.id}`}
              label="Personalizacija"
              checked={openPersonalization}
              onChange={() => setOpenPersonalization(!openPersonalization)}
            />
          )}
        </div>

        {openPersonalization && <TicketInputForm ticket={ticket} />}
        <Trash2
          size={22}
          className="remove-ticket-btn remove-ticket-btn-position"
          onClick={() => handleRemoveTicket(ticket.id)}
        />
      </div>

      {placeType === "hall" && (
        <HallTickets
          ticketId={ticket.id}
          selectedZone={ticket.category}
          userEmail={userEmail}
          userFullName={credentialsRequired ? userFullName : ""}
        />
      )}
    </div>
  );
};

export default Personalization;
