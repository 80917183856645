import React from "react";
import axios from "axios";

import { useConvertCurrency } from "./hooks/currencyExchanger";

import { ShoppingCart } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "./CustomToast";
import {
  setActiveTicket,
  setClientSecretAndAuthenticityToken,
  setIsLoading,
  setZones,
  trimmAlreadyBoughtTickets,
} from "../../../../store/ticket-slice";
import { useTranslate } from "../../../../translations/hooks";
import Button from "../../../../components/Button";

const PaymentButton = ({ handleReserveTickets, orderInfo, concertId }) => {
  const t = useTranslate("buy");

  const locale = useSelector((state) => state.language);
  const loggedinUser = useSelector((state) => state.userState.user);
  const ticketList = useSelector((state) => state.ticketStateExperimental.tickets);
  const totalAmount = useSelector((state) => state.ticketStateExperimental.totalAmount);
  const accumulativeSpendings = useSelector(
    (state) => state.ticketStateExperimental.accumulativeSpendings
  );
  const currency = useSelector((state) => state.ticketStateExperimental.currency);
  const amount = useConvertCurrency((totalAmount + accumulativeSpendings) * 100, currency, 0);
  const isLoading = useSelector((state) => state.ticketStateExperimental.isLoading);

  const dispatch = useDispatch();

  const ticketGenData = { ticketList, totalAmount };

  const createPayment = async () => {
    dispatch(setIsLoading(true));
    if (!handleReserveTickets()) {
      dispatch(setIsLoading(false));
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/monri_payment/create_payment`,
        {
          amount,
          currency,
          orderInfo,
          ticketGenData,
          loggedinUser,
          concertId,
          locale,
        }
      );

      dispatch(
        setClientSecretAndAuthenticityToken({
          authenticityToken: response?.data.authenticity_token,
          clientSecret: response?.data.client_secret,
        })
      );
      dispatch(setZones(response?.data?.zones));
      dispatch(setActiveTicket(0));
      customToast({
        type: "success",
        main: t("reservation-success"),
        details: t("reservation-success-details"),
      });
    } catch (error) {
      customToast({
        type: "error",
        main: error?.response?.data.error,
        details: error?.response?.data?.details,
      });
      if (error?.response?.data?.data?.zones)
        dispatch(setZones(error?.response?.data?.data?.zones));

      if (error?.response?.data?.data?.alreadyBought?.length > 0)
        dispatch(
          trimmAlreadyBoughtTickets({ alreadyBought: error?.response?.data?.data?.alreadyBought })
        );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <Button
      icon={ShoppingCart}
      onClick={createPayment}
      className="payment-footer-btn"
      disabled={isLoading}
    >
      {t("payment")}
    </Button>
  );
};

export default PaymentButton;
