import { ChevronsRight, ChevronsLeft, CirclePlus } from "lucide-react";
import { useSelector } from "react-redux";

const NavigationButtons = ({ onAddTicket, isBeginning, isEnd, swiperRef }) => {
  const tickets = useSelector((state) => state.ticketStateExperimental.tickets);
  const isLastSlide = tickets.length - swiperRef.current.activeIndex === 1 || isEnd;

  return (
    <>
      {!isBeginning && (
        <div className="swiper-button-prev">
          <ChevronsLeft className="pointer-hover" onClick={() => swiperRef.current.slidePrev()} />
        </div>
      )}
      <div className="swiper-button-next">
        {isLastSlide ? (
          <div className="vertical-button pointer-hover" onClick={onAddTicket}>
            <CirclePlus />
            <span>Dodaj</span>
          </div>
        ) : (
          <ChevronsRight className="pointer-hover" onClick={() => swiperRef.current.slideNext()} />
        )}
      </div>
    </>
  );
};

export default NavigationButtons;
