import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { debounce } from "lodash";

import { CircleX } from "lucide-react";

export const InputField = forwardRef(
  (
    {
      label,
      value = "",
      onClick,
      onChange,
      type = "text",
      placeholder,
      className = "",
      grow = false,
      isError = false,
      errorMessage = "",
      style,
      disabled = false,
      shadow = true,
      debounceDelay = 0,
      clearInput = false, // New prop to show the clear button
      ...props
    },
    ref // Forwarded ref
  ) => {
    const [inputValue, setInputValue] = useState(value);

    // Create a debounced version of the onChange function
    const debouncedOnChange = useCallback(
      debounce((value) => {
        onChange(value); // Call the original onChange prop with the debounced value
      }, debounceDelay),
      [debounceDelay, onChange]
    );

    // Handle changes in the input field, update local state and trigger debounced onChange
    const handleChange = (e) => {
      const { value } = e.target;
      setInputValue(value); // Update local state
      debouncedOnChange(e); // Trigger debounced onChange
    };

    // Handle clear input action
    const handleClearInput = () => {
      setInputValue(""); // Clear the local state
      onChange(""); // Trigger onChange with an empty value
    };

    // Cleanup the debounced function when the component unmounts
    useEffect(() => {
      return () => {
        debouncedOnChange.cancel();
      };
    }, [debouncedOnChange]);

    return (
      <div
        className={`input-field-custom-wrapper ${className}`}
        style={{
          ...style,
          flex: grow ? "1" : "",
          opacity: disabled ? 0.7 : 1,
        }}
      >
        {label && <label className="input-label-custom">{label}</label>}
        <div style={{ position: "relative", display: "inline-block" }}>
          <input
            className={`input-field-custom ${isError ? "input-field-error" : ""}`}
            type={type}
            spellCheck="false"
            value={inputValue}
            onChange={handleChange} // Handle input change with debounce
            disabled={disabled}
            onClick={onClick}
            placeholder={placeholder}
            ref={ref} // Attach the ref here
            style={{
              ...(shadow && { boxShadow: "0px 0px 3px 0.5px rgba(218, 218, 218, 0.7)" }),
            }}
            {...props}
          />
          {clearInput && inputValue && <CircleX onClick={handleClearInput} />}
        </div>
        {isError && errorMessage && <div className="input-field-error-message">{errorMessage}</div>}
      </div>
    );
  }
);

export const InputFieldPayment = forwardRef(
  (
    {
      label,
      onClick,
      onChange,
      type = "text",
      placeholder,
      className = "",
      grow = false,
      isError = false,
      errorMessage = "",
      style,
      disabled = false,
      shadow = true,
      ...props
    },
    ref // Forwarded ref
  ) => {
    return (
      <div
        className={`input-field-custom-wrapper ${className}`}
        style={{
          ...style,
          flex: grow ? "1" : "",
          opacity: disabled ? 0.7 : 1,
        }}
      >
        {label && <label className="input-label-custom">{label}</label>}
        <input
          className={`input-field-custom ${isError ? "input-field-error" : ""}`}
          type={type}
          spellCheck="false"
          onChange={onChange}
          disabled={disabled}
          onClick={onClick}
          placeholder={placeholder}
          ref={ref} // Attach the ref here
          style={shadow ? { boxShadow: "0px 0px 3px 0.5px rgba(218, 218, 218, 0.7)" } : {}}
          {...props}
        />
        {isError && errorMessage && <div className="input-field-error-message">{errorMessage}</div>}
      </div>
    );
  }
);

export const CustomSwitcher = ({
  label,
  checked,
  onChange,
  id,
  disabled = false,
  style = {},
  ...props
}) => (
  <div className="switcher-field-custom-wrapper" style={{ ...style, opacity: disabled ? 0.5 : 1 }}>
    {label && (
      <span className="input-label-custom" htmlFor={id}>
        {label}
      </span>
    )}
    <div className="switch">
      <input
        className="switch-input"
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <label htmlFor={id}></label>
    </div>
  </div>
);

export const CustomSelect = ({
  label,
  value,
  onChange,
  options = [],
  style,
  grow = false,
  className = "",
  ...props
}) => {
  return (
    <div className="select-field-custom-wrapper" style={{ ...style, flex: grow ? "1" : "" }}>
      {label && <span className="input-label-custom">{label}</span>}
      <select
        className={`select-custom ${className}`}
        value={value || ""}
        onChange={onChange}
        disabled={!options.length}
        style={style}
        {...props}
      >
        <option value="" disabled hidden>
          {options.length ? "Izaberi" : "Nema opcija"}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
