import { useSelector } from "react-redux";

import { Trash2, Armchair, Tag, Rows3, ContactRound, Mails } from "lucide-react";
import Badge from "./Badge";
import { useConvertCurrency } from "./hooks/currencyExchanger";
import { useTranslate } from "../../../../translations/hooks";
import { lightenHsl } from "./ImageZoneMapper";

const TicketBill = ({ ticket, handleSlideChange, handleRemoveTicket, clientSecret }) => {
  const {
    id: ticketId,
    ticketName,
    category,
    row,
    seat,
    price,
    email,
    color,
    name,
    lname,
  } = ticket;

  const currency = useSelector((state) => state.ticketStateExperimental.currency);
  const activeTicket = useSelector((state) => state.ticketStateExperimental.activeTicket);
  const t = useTranslate("buy");

  const convertedPrice = useConvertCurrency(price, currency, 2);

  const bottomShadow =
    activeTicket === ticketId
      ? {
          boxShadow: `0px 8px 10px -12px ${color}`,
          borderBottom: `solid 1px ${lightenHsl(color, 80)}`,
        }
      : { borderBottom: `solid 1px ${lightenHsl(color, 80)}` };

  const ticketNumberStyle =
    activeTicket === ticketId
      ? { outline: `dashed 2px ${lightenHsl(color, 70)}`, color: lightenHsl(color, 15) }
      : { color: "#888", outlineColor: "#e0e0e0" };
  return (
    <>
      <div className="ticket-bill-row" style={bottomShadow}>
        <div className="ticket-bill-left" onClick={() => handleSlideChange(ticketId - 1)}>
          <div className="flex-container">
            <span className="ticket-number-text" style={ticketNumberStyle}>
              {ticketId}
            </span>
            <Tag style={{ color: color }} className="tag-icon" size={20} />
            <span className="main-text">{category}</span>
            {ticketName && <Badge color={color}>{ticketName}</Badge>}
          </div>

          <div className="flex-container">
            {row && (
              <span className="secondary-text text-with-icon">
                <Rows3 size={16} />
                {t("Row")} <span className="sub-main-text">{row}</span>
              </span>
            )}
            {seat && (
              <span className="secondary-text text-with-icon">
                <Armchair size={16} />
                {t("Seat")} <span className="sub-main-text">{seat}</span>
              </span>
            )}
            {email && (
              <div className="secondary-text text-with-icon">
                <Mails size={16} />
                <span>{email}</span>
              </div>
            )}
            {(name || lname) && (
              <div className="secondary-text text-with-icon">
                <ContactRound size={16} />
                <span>
                  {name} {lname}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="ticket-bill-right">
          <span className="price main-text">{convertedPrice}</span>
          <span className="currency-styles"> {t(currency)}</span>
          {!clientSecret && (
            <Trash2 className="remove-ticket-btn" onClick={() => handleRemoveTicket(ticketId)} />
          )}
        </div>
      </div>
    </>
  );
};

export default TicketBill;
