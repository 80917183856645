import { LoaderCircle } from "lucide-react";

const Button = ({
  children,
  onClick,
  className = "",
  style,
  loader = true,
  icon: Icon, // Icon to display next to the text
  disabled,
  type = "button",
  secondary = false,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={`profile-btn ${className} btn-with-loader ${disabled ? "disabled" : ""} ${
        secondary ? "secondary-btn" : ""
      }`}
      style={style}
      disabled={disabled}
      type={type}
      {...props}
    >
      {loader && disabled ? (
        <LoaderCircle size={20} className="loading-spinner" />
      ) : (
        Icon && <Icon size={20} className="button-icon" />
      )}
      {children}
    </button>
  );
};

export default Button;
