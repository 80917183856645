import { CircleMinus, CirclePlus } from "lucide-react";
import PaymentButton from "./MonriPayment";
const Navigation = ({
  totalTickets,
  handleAddTicket,
  handleRemoveLastTicket,
  handleReserveTickets,
  concertData,
}) => {
  const cantRemoveLastTicket = totalTickets === 0;

  return (
    <div className="buy-navigation-wrapper">
      <div className="buy-navigation">
        <div
          className={`amount-button ${cantRemoveLastTicket ? "disabled-button" : ""}`}
          onClick={!cantRemoveLastTicket ? handleRemoveLastTicket : undefined}
        >
          <CircleMinus />
          <span>Oduzmi</span>
        </div>
        <span className="ticket-number">{totalTickets}</span>
        <div className="amount-button" onClick={handleAddTicket}>
          <CirclePlus />
          <span>Dodaj</span>
        </div>
      </div>
      <PaymentButton
        handleReserveTickets={handleReserveTickets}
        concertId={concertData?._id}
        orderInfo={concertData?.performer_name || "Order info missing"}
      />
    </div>
  );
};

export default Navigation;
