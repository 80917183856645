import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import { useTranslate } from "../../../translations/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setExchangeRates } from "../../../store/exchange-rates-slice";
import {
  resetState,
  setMaxTicketsToBuy,
  setTicketEmail,
  setZones,
} from "../../../store/ticket-slice";
import { getTicektsAvailableToBuy } from "./functions/checkTicketsFunctions";
import PrintPage from "./PrintPage";
import customToast from "./print-page-components/CustomToast";
import { setLoginIsOpen } from "../../../store/loginSlice";

const PrintTickets = () => {
  const [profileData, setProfileData] = useState(null);
  const [concertData, setConcertData] = useState(null);
  const t = useTranslate("buy");
  const { eventId } = useParams();
  const userId = useSelector((state) => state.userState.user) || "";

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userId) {
          customToast({
            type: "error",
            main: t("errors.no_user"),
            details: t("errors.no_user_details"),
          });

          dispatch(setLoginIsOpen(true));
          return;
        }
        if (!eventId) {
          customToast({
            type: "error",
            main: t("errors.no_event"),
            details: t("errors.no_event_details"),
          });
          return;
        }
        const [profileResponse, concertResponse, exchangeRatesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/id/${userId}`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${eventId}`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/v1/helper/exchange_rates`),
        ]);

        const profile = profileResponse.data;

        if (!profile.is_verified) {
          customToast({
            type: "warning",
            main: t("verification_warning"),
            details: t("verification_warning_details", { email: profile.fbEmail }),
            time: 10000,
          });
        }
        const concert = concertResponse.data[0];

        const maxTicketsToBuy = getTicektsAvailableToBuy({
          profileData: profile,
          concertId: concert?._id,
          maxTicketsPerUser: concert?.max_tickets_per_user,
        });

        setConcertData(concert);
        setProfileData(profile);
        dispatch(setExchangeRates(exchangeRatesResponse.data.exchangeRates));
        dispatch(setMaxTicketsToBuy(maxTicketsToBuy));
        dispatch(setZones(concert.tickets.online_sale.zones));
        dispatch(setTicketEmail(profile.email));
      } catch (error) {
        toast.error(
          `Problem pri dohvatanju podataka. Pokušajte se odjaviti i ponovo prijaviti.`,
          toastSetup("top-right", 3000)
        );
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    dispatch(resetState());
  }, [eventId, userId]);

  return (
    <span className="print-ticket-manager-modal-window">
      {/* <div className="close-modal" onClick={() => setOpenPrintTickets(false)}>
        <CircleX />
      </div> */}
      {concertData && (
        <PrintPage
          concertData={concertData}
          setConcertData={setConcertData}
          profileData={profileData}
          t={t}
        />
      )}
    </span>
  );
};

export default PrintTickets;
