const SeatDisplayOrganize = ({ eventData, setEventData, selectedZone }) => {
  const selectedZoneData = eventData.tickets.online_sale.zones[selectedZone];
  const orientation = selectedZoneData.orientation;

  const { rows = "column-reverse", seats } = orientation || {};

  const toggleRow = (row) => {
    setEventData((prevEventData) => {
      const updatedZones = { ...prevEventData.tickets.online_sale.zones };
      const updatedRows = { ...updatedZones[selectedZone].rows };
      const updatedSeats = updatedRows[row].seats.length
        ? []
        : Array.from({ length: Number(updatedRows[row].total_seats) }, (_, i) => i + 1); //

      updatedRows[row] = { ...updatedRows[row], seats: updatedSeats };

      const currentAmount = Object.values(updatedRows).reduce(
        (acc, row) => acc + row.seats.length,
        0
      );

      updatedZones[selectedZone] = {
        ...updatedZones[selectedZone],
        rows: updatedRows,
        amount: currentAmount,
        max_amount:
          updatedZones[selectedZone].max_amount -
          (updatedZones[selectedZone].amount - currentAmount),
      };

      return {
        ...prevEventData,
        tickets: {
          ...prevEventData.tickets,
          online_sale: { ...prevEventData.tickets.online_sale, zones: updatedZones },
        },
      };
    });
  };

  const toggleSeats = ({ row, seat }) => {
    setEventData((prevEventData) => {
      const updatedZones = { ...prevEventData.tickets.online_sale.zones };
      const updatedRows = { ...updatedZones[selectedZone].rows };
      const seatIndex = updatedRows[row].seats.indexOf(seat);

      if (seatIndex !== -1) {
        updatedRows[row].seats = updatedRows[row].seats.filter((s) => s !== seat);
      } else {
        updatedRows[row].seats = [...updatedRows[row].seats, seat];
        updatedRows[row].seats.sort((a, b) => a - b);
      }

      const currentAmount = Object.values(updatedRows).reduce(
        (acc, row) => acc + row.seats.length,
        0
      );

      updatedZones[selectedZone] = {
        ...updatedZones[selectedZone],
        rows: updatedRows,
        amount: currentAmount,
        max_amount:
          updatedZones[selectedZone].max_amount -
          (updatedZones[selectedZone].amount - currentAmount),
      };

      return {
        ...prevEventData,
        tickets: {
          ...prevEventData.tickets,
          online_sale: { ...prevEventData.tickets.online_sale, zones: updatedZones },
        },
      };
    });
  };

  return (
    <div className="rows-container" style={{ flexDirection: rows }}>
      {Object.entries(selectedZoneData.rows).map(([key, value]) => {
        return (
          <div key={key} className="seats-container" style={{ flexDirection: seats || "row" }}>
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
            {Array.from({ length: Number(value.total_seats) }, (_, i) => {
              const seat = i + 1;
              const notPrinted = selectedZoneData.rows[key].seats.includes(seat);
              let className = "seats free-seat";
              if (notPrinted) className = "selected-seat seats";

              return (
                <div
                  key={`${i}-${key}-${seat}`}
                  className={className}
                  onClick={() => toggleSeats({ row: key, seat })}
                ></div>
              );
            })}
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SeatDisplayOrganize;
