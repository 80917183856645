import { toast } from "react-toastify";

import {
  checkForDuplicateCredentials,
  validateInput,
} from "../pages/profile-page/check-reseller-tickets/functions/checkTicketsFunctions";
import { assignColorsToZones } from "../pages/profile-page/check-reseller-tickets/functions/asignZoneColors";

const { createSlice } = require("@reduxjs/toolkit");
// Calculate accumulative spendings helper
const calculateAccumulativeSpendings = (totalAmount, ticketAmount) => {
  return ticketAmount * 1.06 + (totalAmount + ticketAmount) * 0.056;
};

const ticketSlicePrint = createSlice({
  name: "tickets",
  initialState: {
    tickets: [
      {
        id: 1,
        name: "",
        lname: "",
        email: "",
        price: 0,
        errors: [],
      },
    ],
    totalAmount: 0,
    accumulativeSpendings: 0,
    maxTicketsToBuy: 4,
    currency: "BAM",
    activeTicket: 1,
    email: "",
    zones: {},
    authenticityToken: "",
    clientSecret: "",
    isLoading: false,
  },

  reducers: {
    addTicket(state, action) {
      const {
        categoryErrorMsg,
        ticketAvailableErorrMsg,
        chooseCategoryErrorMsg,
        chooseSeatErrorMsg,
      } = action.payload;
      const previousTicket = state.tickets[state.tickets.length - 1];
      const category = previousTicket?.category || "";
      const availableTickets = state.zones[category]?.amount || 0;
      const bookedTickets = state.tickets.filter((ticket) => ticket.category === category).length;
      const categoryHasRows =
        state.zones[category]?.rows && Object.values(state.zones[category]?.rows).length > 0;

      if (state.tickets.length > 0 && !category) {
        toast.info(chooseCategoryErrorMsg);
        return;
      }
      if (
        state.tickets.length > 0 &&
        categoryHasRows &&
        (!previousTicket?.row || !previousTicket?.seat)
      ) {
        toast.info(chooseSeatErrorMsg);
        return;
      }

      // Check if buyer can buy more tickets
      if (state.tickets.length >= state.maxTicketsToBuy) {
        toast.error(ticketAvailableErorrMsg);
        return;
      }
      if (state.tickets.length > 0 && availableTickets - bookedTickets < 1) {
        toast.warn(categoryErrorMsg);
        state.tickets.push({
          id: state.tickets.length + 1,
          email: state.email,
          price: 0,
          name: "",
          lname: "",
          errors: [],
        });
        return;
      }

      const newTicket = {
        id: state.tickets.length + 1,
        category,
        name: "",
        lname: "",
        price: previousTicket?.price || 0,
        email: state.email,
        ticketName: previousTicket?.ticketName || "",
        row: previousTicket?.row || "",
        color: previousTicket?.color || "",
        errors: [],
      };

      state.tickets.push(newTicket);
      state.totalAmount += newTicket.price;
      state.accumulativeSpendings = calculateAccumulativeSpendings(
        state.totalAmount,
        state.tickets.length
      );
    },
    selectTicket(state, action) {
      const { ticketId, price, ticketName, category, seat, row, color, ticketAvailableErrorMsg } =
        action.payload;
      const previousTicket = state.tickets[ticketId - 1];

      // Check if the selected category has available tickets
      if (
        state.zones[category]?.amount -
          state.tickets.filter((ticket) => ticket.category === category).length <
        1
      ) {
        toast.error("Zona nije dostupna");
        return;
      }

      // If the selected category is the same as the previous ticket, add new ticket
      if (
        state.tickets.length === 0 ||
        (state.tickets[ticketId - 1]?.category &&
          (category === previousTicket?.category || !previousTicket?.category))
      ) {
        // Check if buyer can buy more tickets
        if (state.tickets.length >= state.maxTicketsToBuy) {
          toast.error(ticketAvailableErrorMsg);
          return;
        }
        const newTicket = {
          id: state.tickets.length + 1,
          category,
          name: "",
          lname: "",
          price: previousTicket?.price || 0,
          email: state.email,
          ticketName: previousTicket?.ticketName || "",
          row: previousTicket?.row || "",
          color: previousTicket?.color || "",
          errors: [],
        };

        state.tickets.push(newTicket);
        state.totalAmount += newTicket.price;
        state.accumulativeSpendings = calculateAccumulativeSpendings(
          state.totalAmount,
          state.tickets.length
        );
      }

      const ticketIndex = state.tickets.findIndex((ticket) => ticket?.id === ticketId);

      if (ticketIndex !== -1) {
        state.tickets[ticketIndex] = {
          ...state.tickets[ticketIndex],
          price,
          category,
          seat,
          row,
          ticketName,
          color,
        };
        state.totalAmount = state.tickets.reduce((sum, ticket) => sum + (ticket?.price || 0), 0);
      }

      // Update accumulative spendings based on updated total amount
      state.accumulativeSpendings = calculateAccumulativeSpendings(
        state.totalAmount,
        state.tickets.length
      );
    },
    selectSeat(state, action) {
      const {
        ticketId,
        activeTicket,
        price,
        ticketName,
        category,
        seat,
        row,
        color,
        isSeatReserved,
      } = action.payload;

      // Check if the selected category has available tickets
      if (
        state.zones[category]?.amount -
          state.tickets.filter((ticket) => ticket.category === category).length <
        1
      ) {
        toast.error("Zona nije dostupna");
        return;
      }

      // In case there are no tickets skip this, otherwise assign the seat to the selected ticket
      if (
        state.tickets.length &&
        (!state.tickets[activeTicket - 1]?.row || !state.tickets[activeTicket - 1]?.seat)
      ) {
        state.tickets[activeTicket - 1] = {
          ...state.tickets[activeTicket - 1],
          price,
          category,
          seat,
          row,
          ticketName,
          color,
        };
      } else if (isSeatReserved) {
        state.tickets = state.tickets.filter((ticket) => ticket.id !== ticketId);

        // Refine remaining ticketsId
        state.tickets.forEach((ticket, index) => {
          ticket.id = index + 1;
        });
      } else if (seat && row) {
        if (state.maxTicketsToBuy <= state.tickets.length) {
          toast.error("Dostigli ste maksimalan broj karata za kupovinu.");
          return;
        }
        state.tickets.push({
          id: state.tickets.length + 1,
          email: state.email,
          price,
          category,
          seat,
          row,
          ticketName,
          color,
          errors: [],
        });
      }

      state.totalAmount = state.tickets.reduce((sum, ticket) => sum + (ticket?.price || 0), 0);

      // Update accumulative spendings based on updated total amount
      state.accumulativeSpendings = calculateAccumulativeSpendings(
        state.totalAmount,
        state.tickets.length
      );
    },
    removeTicket(state, action) {
      const deletingId = action.payload;

      state.tickets = state.tickets.filter((ticket) => ticket?.id !== deletingId);

      state.tickets.forEach((ticket, index) => {
        ticket.id = index + 1;
      });

      state.totalAmount = state.tickets.reduce((sum, ticket) => sum + (ticket?.price || 0), 0);

      state.accumulativeSpendings = calculateAccumulativeSpendings(
        state.totalAmount,
        state.tickets.length
      );
    },

    updateTicketInfo(state, action) {
      const { ticketId, property, value } = action.payload;
      const ticketIndex = state.tickets.findIndex((ticket) => ticket?.id === ticketId);

      if (ticketIndex !== -1) {
        state.tickets[ticketIndex] = {
          ...state.tickets[ticketIndex],
          [property]: value,
        };
      }
      const inputErrors = validateInput({ type: property, value });
      const duplicateErrors =
        state.tickets.length > 1 ? checkForDuplicateCredentials(state.tickets) : [];

      state.tickets.forEach((ticket) => {
        ticket.errors = [];

        if (ticket.id === ticketId) ticket.errors = [...inputErrors];

        if (duplicateErrors.includes(ticket.id)) {
          ticket.errors.push("name_duplicate");
        }
      });
    },
    presetTicket(state, action) {
      const { category, ticketName, price, color, name = "", lname = "" } = action.payload;

      state.tickets = [
        {
          id: 1,
          name,
          lname,
          email: state.email,
          ticketName,
          price,
          category,
          color,
          errors: [],
        },
      ];
      state.totalAmount = price;
      state.accumulativeSpendings = calculateAccumulativeSpendings(
        state.totalAmount,
        state.tickets.length
      );
    },
    resetState(state, action) {
      const email = action.payload;

      state.tickets = [
        {
          id: 1,
          email,
          name: "",
          lname: "",
          price: 0,
          errors: [],
        },
      ];
      state.activeTicket = 1;
      state.totalAmount = 0;
      state.accumulativeSpendings = 0;
    },

    softResetState(state, action) {
      state.tickets = state.tickets.map((ticket) => ({
        ...ticket,
        price: 0,
        category: "",
        seat: "",
        row: "",
      }));
      state.totalAmount = 0;
      state.accumulativeSpendings = 0;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setZones(state, action) {
      state.zones = assignColorsToZones(action.payload);
    },
    setActiveTicket(state, action) {
      state.activeTicket = action.payload;
    },
    setMaxTicketsToBuy(state, action) {
      state.maxTicketsToBuy = action.payload;
    },
    setClientSecretAndAuthenticityToken(state, action) {
      const { clientSecret, authenticityToken } = action.payload;
      state.clientSecret = clientSecret;
      state.authenticityToken = authenticityToken;
    },
    setTicketEmail(state, action) {
      state.email = action.payload;
      if (state.tickets.length > 0) state.tickets[0].email = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    trimmAlreadyBoughtTickets(state, action) {
      const { alreadyBought } = action.payload;

      // Create a set for quick lookup of already bought ticket identifiers
      const boughtTicketsSet = new Set(
        alreadyBought.map(({ category, row, seat }) => `${category}|||${row}|||${seat}`)
      );

      state.tickets.forEach((ticket) => {
        const ticketIdentifier = `${ticket.category}|||${ticket.row}|||${ticket.seat}`;
        if (boughtTicketsSet.has(ticketIdentifier)) {
          ticket.seat = ""; // Clear the seat property of the matching ticket
        }
      });
    },
  },
});

export const {
  addTicket,
  removeTicket,
  selectTicket,
  resetState,
  softResetState,
  updateTicketInfo,
  presetTicket,
  setCurrency,
  selectSeat,
  setZones,
  setActiveTicket,
  setTicketEmail,
  setMaxTicketsToBuy,
  setClientSecretAndAuthenticityToken,
  trimmAlreadyBoughtTickets,
  setIsLoading,
} = ticketSlicePrint.actions;

export const ticketStateExperimental = ticketSlicePrint.reducer;
