import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../../../components/Button";
import { CustomSelect, InputField } from "../organize-event-page/Inputs";
import { Clock, CheckCheck, CircleX } from "lucide-react";
import { getFormatedDate } from "../check-reseller-tickets/print-page-components/hooks/utls";

const STATUS_OPTIONS = [
  { value: "pending", label: "Na čekanju", icon: <Clock size={16} /> },
  { value: "approved", label: "Odobren", icon: <CheckCheck size={16} /> },
  { value: "declined", label: "Odbijen", icon: <CircleX size={16} /> },
];

const PaymentRequestTable = () => {
  // State for table data and filters
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    orderNumber: "",
    status: "approved",
    concertId: "",
    fullName: "",
    email: "",
    dateFrom: "",
    dateTo: "",
    sortBy: "",
    sortOrder: "desc",
    page: 1,
    limit: 20,
  });

  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pages: 1,
  });

  // const fetchEvents = async (query) => {
  //   const response = await axios.get(
  //     `${process.env.REACT_APP_API_URL}/api/v1/payment_request/search/events`,
  //     {
  //       params: { query, limit: 10 },
  //     }
  //   );
  //   return response.data.data.map((event) => ({
  //     value: event._id,
  //     label: event.performer_name + " - " + event.place.city,
  //   }));
  // };

  // Fetch data from the API
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/payment_request`, {
        params: filters,
      });
      setData(response.data.data);
      setPagination(response.data.pagination);
    } catch (err) {
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when filters change
  useEffect(() => {
    fetchData();
  }, [filters]);

  // Update filter state
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFilters({ ...filters, [name]: value, page: 1 }); // Reset to page 1 on filter change
  };

  // Handle sorting
  const handleSort = (field) => {
    const sortOrder = filters.sortBy === field && filters.sortOrder === "asc" ? "desc" : "asc";
    setFilters({ ...filters, sortBy: field, sortOrder });
  };

  // Handle pagination
  const handlePageChange = (newPage) => {
    setFilters({ ...filters, page: newPage });
  };

  return (
    <div className="payment-request-table">
      <h4 className="tab-header">Pregled narudžbi</h4>
      {/* Filters */}
      <div className="filter-wrapper">
        <div className="filters">
          <InputField
            shadow={false}
            label="Broj narudžbe"
            type="number"
            name="orderNumber"
            placeholder="Broj narudžbe"
            value={filters.orderNumber}
            debounceDelay={500}
            onChange={handleFilterChange}
          />
          <InputField
            shadow={false}
            label="Email"
            type="email"
            name="email"
            placeholder="Email"
            value={filters.email}
            debounceDelay={500}
            onChange={handleFilterChange}
          />
          <CustomSelect
            label="Status"
            options={STATUS_OPTIONS}
            value={filters.status}
            name="status"
            onChange={(e) => handleFilterChange(e)}
          />

          <InputField
            shadow={false}
            label="Naziv događaja"
            type="text"
            name="performerName"
            placeholder="Naziv događaja"
            value={filters.performerName}
            debounceDelay={500}
            onChange={handleFilterChange}
          />
          <InputField
            shadow={false}
            label="Ime kupca"
            type="text"
            name="fullName"
            placeholder="Ime kupca"
            value={filters.fullName}
            debounceDelay={500}
            onChange={handleFilterChange}
          />

          {/* <SelectCustom
          name="events"
          label="Select Event"
          fetchOptions={fetchEvents}
          value={filters.concertId}
          onChange={(value) => setFilters({ ...filters, concertId: value })}
          /> */}
          <InputField
            shadow={false}
            label="Datum od"
            type="date"
            name="dateFrom"
            value={filters.dateFrom}
            onChange={handleFilterChange}
          />
          <InputField
            shadow={false}
            label="Datum do"
            type="date"
            name="dateTo"
            value={filters.dateTo}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort("orderNumber")}>Broj narudžbe</th>
              <th>Email</th>
              <th className="text-center" onClick={() => handleSort("status")}>
                Status
              </th>
              <th className="text-center">Događaj</th>
              <th>Ime kupca</th>
              <th className="text-center">Cijena</th>
              <th className="text-center">Valuta</th>
              <th onClick={() => handleSort("createdAt")}>Napravljen</th>

              <th className="text-center">Generirano</th>
              <th className="text-center">Dostavljeno</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item) => (
                <tr key={item._id}>
                  <td>{item.orderNumber}</td>
                  <td>{item.ticketGenData.ticketList[0].email}</td>
                  <td className="text-center">
                    {STATUS_OPTIONS.find((option) => option.value === item.status).icon}
                  </td>
                  <td className="text-center">{item.concertData.performer_name}</td>
                  <td>{item.userData.full_name}</td>
                  <td className="text-center">{item.totalPrice}</td>
                  <td className="text-center">{item.currency}</td>
                  <td>{getFormatedDate(item.createdAt, true, "hr-HR", "numeric")}</td>
                  <td className="text-center">
                    {item?.ticketCreate ? <CheckCheck size={16} /> : <CircleX size={16} />}
                  </td>
                  <td className="text-center">
                    {item?.tickets_delivered ? <CheckCheck size={16} /> : <CircleX size={16} />}
                  </td>

                  {/* <td className="actions">
                    <Button secondary={true} onClick={() => alert(`View ${item._id}`)}>
                      View
                    </Button>
                    <Button secondary={true} onClick={() => alert(`Edit ${item._id}`)}>
                      Edit
                    </Button>
                    <Button secondary={true} onClick={() => alert(`Delete ${item._id}`)}>
                      Delete
                    </Button>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">Nema podataka</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {/* Pagination */}
      <div className="pagination">
        {/* Show total documents */}
        <span className="secondary-text">Ukupno dokumenata: {pagination.total}</span>

        {/* Previous Page Button */}
        <Button
          disabled={pagination.page === 1}
          loader={false}
          secondary={true}
          onClick={() => handlePageChange(pagination.page - 1)}
        >
          Prethodna
        </Button>

        {/* Render pagination buttons */}
        {Array.from({ length: pagination.pages }, (_, index) => index + 1)
          .filter(
            (page) =>
              page <= 3 || // Show first 3 pages
              page === pagination.page || // Show current page
              page === pagination.pages // Show the last page
          )
          .reduce((pages, page, index, arr) => {
            // Add dots (...) between non-consecutive pages
            if (index > 0 && page > arr[index - 1] + 1) {
              pages.push("...");
            }
            pages.push(page);
            return pages;
          }, [])
          .map((page, index) =>
            page === "..." ? (
              <span key={index} className="pagination-dots">
                . . .
              </span>
            ) : (
              <Button
                key={page}
                disabled={pagination.page === page}
                loader={false}
                secondary={true}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Button>
            )
          )}

        {/* Next Page Button */}
        <Button
          disabled={pagination.page === pagination.pages}
          loader={false}
          secondary={true}
          onClick={() => handlePageChange(pagination.page + 1)}
        >
          Sljedeća
        </Button>
      </div>
    </div>
  );
};

export default PaymentRequestTable;
