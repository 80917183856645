import { InputField } from "../../organize-event-page/Inputs";
import { useTranslate } from "../../../../translations/hooks";
import { memo } from "react";

const TicketInputField = memo(({ ticket, property, handleUpdateTicket }) => {
  const t = useTranslate("common");

  return (
    <InputField
      className={`buy-input ${property}-input`}
      placeholder={t(property)}
      type="text"
      shadow={false}
      value={ticket[property] || ""}
      isError={ticket.errors.some((error) => error.startsWith(`${property}_`))}
      errorMessage={
        ticket.errors.find((error) => error.startsWith(`${property}_`))
          ? t(ticket.errors.find((error) => error.startsWith(`${property}_`)))
          : ""
      }
      onChange={(e) => handleUpdateTicket(property, e.target.value)}
    />
  );
});

export default TicketInputField;
