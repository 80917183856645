import { useSelector } from "react-redux";

export const useConvertCurrency = (amount, currency, precision = 2) => {
  const exchangeRatesData = useSelector((state) => state.exchangeRates);
  if (!amount) return 0;

  const amountNum = Number(amount);

  switch (currency) {
    case "EUR":
      return Number((amountNum * exchangeRatesData.EUR).toFixed(precision));
    case "USD":
      return Number((amountNum * exchangeRatesData.USD).toFixed(precision));
    default:
      return Number(amountNum?.toFixed(precision));
  }
};
