export const getFormatedDate = (date, includeTime = false, locale = "hr-HR", month = "long") => {
  const options = { day: "numeric", month, year: "numeric" };

  if (includeTime) {
    options.hour = "numeric";
    options.minute = "numeric";
  }

  if (!date) return ""; // Return an empty string if date is invalid
  return new Intl.DateTimeFormat(locale, options).format(new Date(date));
};
