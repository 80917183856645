import axios from "axios";
import { useState } from "react";

import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";

import { useDispatch, useSelector } from "react-redux";
import { setLoginIsOpen } from "../../../store/loginSlice";

import {
  BadgeDollarSign,
  Armchair,
  Tag,
  Rows3,
  ContactRound,
  Mails,
  Download,
  MapPin,
} from "lucide-react";
import Button from "../../../components/Button";

export const MyTicketCard = ({ data, ticketId, orderNumber, performer }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const language = useSelector((state) => state.language);

  if (userId === "" && token === "") {
    dispatch(setLoginIsOpen(true));
  }

  const downloadTicket = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/download/${orderNumber}/${ticketId}`,
        {
          userId,
          token,
          language,
        },
        {
          responseType: "blob", // Expecting a blob response
        }
      );

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${performer}-${ticketId + 1}.pdf`); // Specify the file name for PDF

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success(
        language === "en" ? "Ticket downloaded successfully!" : "Uspješno preuzeta ulaznica!",
        toastSetup("top-center", 5000)
      );
    } catch (error) {
      if (error.response) {
        const blob = error.response.data;
        const reader = new FileReader();

        reader.onload = () => {
          const jsonResponse = JSON.parse(reader.result);
          toast.error(
            language === "en"
              ? `Error: ${jsonResponse.message} Please report the issue to info@event.ba`
              : `Greška: ${jsonResponse.message} Prijavite problem na info@event.ba`,
            toastSetup("top-right", 6000)
          );
        };

        reader.readAsText(blob); // Read the blob as text to parse as JSON
      } else {
        // Handle other errors (network, etc.)
        toast.error(
          language === "en"
            ? "Network error: Please try again later."
            : "Greška u mreži: Pokušajte ponovo kasnije.",
          toastSetup("top-right", 6000)
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ticket-card">
      {data.name && data.lname && (
        <div className="text-with-icon">
          <ContactRound size={20} />
          {data.name} {data.lname}
        </div>
      )}
      {data.email && (
        <div className="text-with-icon">
          <Mails size={20} />
          {data.email}
        </div>
      )}
      {data.category && (
        <div className="text-with-icon">
          <MapPin size={20} />
          {data.category}
        </div>
      )}
      {data.ticketName && (
        <div className="text-with-icon">
          <Tag size={20} />
          {data.ticketName}
        </div>
      )}
      {data.row && (
        <div className="text-with-icon">
          <Rows3 size={20} />
          Red {data.row}
        </div>
      )}
      {data.seat && (
        <div className="text-with-icon">
          <Armchair size={20} />
          Sjedalo {data.seat}
        </div>
      )}
      {data.price && (
        <div className="text-with-icon">
          <BadgeDollarSign size={20} />
          {data.price} KM
        </div>
      )}
      <Button
        className="aling-self-end"
        disabled={loading}
        icon={Download}
        onClick={downloadTicket}
      >
        <span>{language === "en" ? "Download" : "Preuzmi"}</span>
      </Button>
    </div>
  );
};
