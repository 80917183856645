import { createSlice } from "@reduxjs/toolkit";

const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState: {
    BAM: 1,
    EUR: 0.52,
    USD: 0.54,
  },
  reducers: {
    setExchangeRates: (state, action) => action.payload,
  },
});

export const { setExchangeRates } = exchangeRatesSlice.actions;
export default exchangeRatesSlice.reducer;
