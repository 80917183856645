import { useState, useEffect } from "react";
import { useTranslate } from "../../../../translations/hooks";
import customToast from "../print-page-components/CustomToast";
import { getFormatedDate } from "../print-page-components/hooks/utls";
import axios from "axios";
import { useSelector } from "react-redux";

export const usePresaleAccess = (concert_id, email, sale_start, on_sale, maxTicketsToBuy) => {
  const [hasAccess, setHasAccess] = useState(null); // Track access state
  const locale = useSelector((state) => state.language) || "hr-HR";

  const t = useTranslate("buy");

  useEffect(() => {
    const checkPresaleAccess = async () => {
      if (!on_sale || maxTicketsToBuy <= 0) {
        setHasAccess(false);
        return;
      }

      if (!sale_start || (sale_start && new Date(sale_start).getTime() < Date.now())) {
        setHasAccess(true);
        return;
      }

      if (!concert_id || !email) {
        customToast({
          type: "warning",
          main: t("presale.error_message"),
          details: t("presale.error_details"),
        });
        setHasAccess(false);
        return;
      }

      try {
        const endpoint = `/api/v1/presale/check/${concert_id}/${email}`;
        const url = new URL(endpoint, process.env.REACT_APP_API_URL).href;
        const response = await axios.get(url);
        if (response?.data) {
          customToast({
            type: "success",
            main: t("presale.success_message"),
            details: t("presale.success_details"),
          });
          setHasAccess(true);
        } else {
          customToast({
            type: "warning",
            main: t("presale.error_message_no_access"),
            details: t("presale.error_details_no_access", {
              time: getFormatedDate(sale_start, true, locale),
            }),
          });
          setHasAccess(false);
        }
      } catch (error) {
        console.error("Error checking presale access:", error);
        customToast({
          type: "error",
          main: t("presale.error_message_check"),
          details: t("presale.error_details_check"),
        });
        setHasAccess(false);
      }
    };

    checkPresaleAccess();
  }, [concert_id, email, sale_start]);

  return hasAccess;
};
