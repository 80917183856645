import { useCallback } from "react";
import HallTicketCard from "./HallTicketCard";
import { useDispatch, useSelector } from "react-redux";
import { selectTicket } from "../../../../store/ticket-slice";
import { useTranslate } from "../../../../translations/hooks";

const HallTickets = ({ selectedZone, userEmail, userFullName, ticketId }) => {
  const dispatch = useDispatch();
  const onlineSaleZones = useSelector((state) => state.ticketStateExperimental.zones);
  const t = useTranslate("buy");

  const handleClickZone = useCallback(
    async (zone, ticketName, color) => {
      await dispatch(
        selectTicket({
          price: Number(onlineSaleZones[zone].price),
          ticketId,
          category: zone,
          ticketName,
          color: color,
          ticketAvailableErrorMsg: t("ticket_available_error_msg"),
        })
      );
    },
    [dispatch, onlineSaleZones, ticketId]
  );
  const tickets = useSelector((state) => state.ticketStateExperimental.tickets);

  return (
    <div className="ticket-cards">
      {Object.entries(onlineSaleZones).map(([zone, { price, name, color, amount }]) => {
        const amountLeft =
          Number(amount) - tickets.filter((ticket) => ticket.category === zone).length;

        if (!price || !name || amount < 1) return null;

        return (
          <HallTicketCard
            key={zone}
            price={price}
            name={name}
            zone={zone}
            userEmail={userEmail}
            userFullName={userFullName}
            isDisabled={amountLeft === 0}
            selectedZone={selectedZone}
            color={color}
            amount={amountLeft}
            onClick={() => handleClickZone(zone, name, color)}
          />
        );
      })}
    </div>
  );
};

export default HallTickets;
